import React from "react";
import { useSelector } from "react-redux";
import { createOptions } from "../../../../utils";
import { getContactTagDropdown } from "../../../../selectors/dropdowns";
import { Select } from "../../../helpers";

const ContactTagSelect = ({ values, onChange, placeholder = "All" }) => {
  const contactTagOptions = createOptions(
    useSelector(getContactTagDropdown),
    "tag_id",
    "label",
    { addAll: true },
  );

  return (
    <Select
      value={values}
      onChange={(e) => onChange(e?.value || null)}
      isClearable={values !== ""}
      options={contactTagOptions}
      placeholder={placeholder}
    />
  );
};

export default ContactTagSelect;
